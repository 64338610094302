var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.ModalConfirm,{attrs:{"isModalOpen":_vm.isModalDisableInterimDisplayed,"title":_setup.isMultipleWorkers ? _setup.translate('modals.disableInterimGroup.title') : _setup.translate('modals.disableInterim.title'),"subtitle":_setup.isMultipleWorkers
                ? _setup.translate('modals.disableInterimGroup.description')
                : _setup.translate('modals.disableInterim.description', { name: _vm.workerName }),"maxWidth":'600px',"id":"ModalConfirmInactive"}},[_c(_setup.Select,{attrs:{"label":_setup.translate('form.reason') + ' :',"options":_setup.inactivatedReasonOptions,"width":"100%"},on:{"select":_setup.handleSelectInactivatedOption},model:{value:(_setup.inactivatedReasonsSelected.label),callback:function ($$v) {_vm.$set(_setup.inactivatedReasonsSelected, "label", $$v)},expression:"inactivatedReasonsSelected.label"}}),(_setup.isOtherReason)?_c(_setup.TextareaInput,{attrs:{"rows":4,"size":"md","required":"","label":_setup.translate('modals.inactiveInterim.complementReason')},model:{value:(_setup.inactivatedReasonsSelected.complement),callback:function ($$v) {_vm.$set(_setup.inactivatedReasonsSelected, "complement", $$v)},expression:"inactivatedReasonsSelected.complement"}}):_vm._e(),_c('div',{staticClass:"button-container"},[_c(_setup.Link,{attrs:{"label":"Annuler","underline":"","type":"neutral"},on:{"click":function($event){return _setup.emit('closeModalDisableInterim')}}}),_c(_setup.Button,{attrs:{"label":_setup.translate('modals.global.confirm'),"size":"sm","loading":_setup.isDisableInterimLoading || _setup.isDisableMultipleInterimLoading},on:{"click":_setup.handleInactivateWorker}})],1)],1),_c(_setup.ModalConfirm,{attrs:{"isModalOpen":_setup.isModalRemoveInterimFromMissions,"title":_setup.isMultipleWorkers
                ? _setup.translate('modals.removeDeactivatedGroupFromMission.title')
                : _setup.translate('modals.removeDeactivatedInterimFromMission.title'),"subtitle":_setup.isMultipleWorkers
                ? _setup.translate('modals.removeDeactivatedGroupFromMission.description')
                : _setup.translate('modals.removeDeactivatedInterimFromMission.description', { name: _vm.workerName }),"maxWidth":'600px',"id":"ModalConfirmRemoveMissions"}},[_c('div',{staticClass:"button-container"},[_c(_setup.Link,{attrs:{"label":"Annuler","underline":"","type":"neutral"},on:{"click":_setup.cancelRemoveWorkerFromMissions}}),_c(_setup.Button,{attrs:{"label":_setup.translate('modals.global.confirm'),"size":"sm","loading":_setup.isRemoveInterimFromMissionsLoading},on:{"click":_setup.handleRemoveWorkerFromMissions}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }