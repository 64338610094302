import { ref } from "vue";
import store from "@/store";

export const useDisableMultipleWorkers = () => {
    const loading = ref(false);
    const disableMultipleWorkers = async ({ workersId, data }: { workersId: string[]; data: { reason: string; reasonComplement: string } }) => {
        try {
            loading.value = true;
            await store.dispatch("actions/changeInterimActivityGroup", {
                interimIds: workersId,
                status: "inactive",
                inactivityReason: data.reason,
                reasonComplement: data.reasonComplement,
            });
        } catch (e) {
            throw e;
        } finally {
            loading.value = false;
        }
    };
    return { loading, disableMultipleWorkers };
};
